.AppHeader {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 42px;
  background-color: #454545;
}

.AppHeader-link {
  text-decoration: none;
  color: white;
}

.AppHeader-link:visited {
  color: white;
}

.AppHeader-link:hover {
  background-color: #666666;
  cursor: pointer;
}

.AppHeader-container {
  position: relative;
  width: 960px;
  margin: 0 auto;
}

.AppHeader-center {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 260px;
  left: 350px;
}

.AppHeader-title {
  margin: 0 10px;
  height: 42px;
  font-size: 32px;
}

.AppHeader-version {
  height: 24px;
  font-size: 16px;
}

.AppHeader-backTop {
  position: absolute;
  padding: 0 10px;
  right: 60px;
  top: 14px;
  height: 22px;
  font-size: 18px;
}

.AppHeader-config {
  position: absolute;
  padding: 0 10px;
  right: 0px;
  top: 14px;
  height: 22px;
  font-size: 18px;
}
