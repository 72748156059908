.Rooms-headline {
  text-align: center;
  font-size: 22px;
}

.Rooms-tiles-wrapper {
  width: 960px;
  padding: 32px 0;
  margin: auto;
  line-height: 100px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  background-color: #ffffff;
}

.Rooms-tile {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 100px;
  margin: 10px;
  vertical-align: middle;
  background-color: #aed4b0;
  color: #282c34;
  border: solid 4px #454545;
}

.Rooms-tile:hover {
  background-color: #ccfacf;
}

.Rooms-tileLink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #282c34;
}

.Rooms-tileUserNum {
  position: absolute;
  top: 0;
  right: 0;
  height: 32px;
  width: 32px;
  color: #282c34;
  background-color: #ffffff;
  line-height: 32px;
  font-size: 18px;
}

.Rooms-contactInfo {
  margin: 24px 0 0 0;
}

.Rooms-contactInfoContent {
  text-align: center;
}
