.Config {
  position: relative;
  margin: 32px auto 0px auto;
  width: 960px;
  height: 540px;
  background-color: #ffffff;
}

.Config-se {
  position: absolute;
  top: 240px;
  left: 50px;
}

.Config-seRange {
  position: absolute;
  top: 240px;
  left: 170px;
  width: 160px;
}

.Config-seRange input {
  width: 100%;
}

.Config-seRangeValue {
  position: absolute;
  top: 240px;
  left: 340px;
}

.Config-title {
  position: absolute;
  top: 40px;
  left: 50px;
  font-size: 26px;
}

.Config-name {
  position: absolute;
  top: 100px;
  left: 50px;
}

.Config-nameInput {
  position: absolute;
  top: 100px;
  left: 170px;
  width: 160px;
}

.Config-nameButton {
  position: absolute;
  top: 100px;
  left: 340px;
}

.Config-puyoSkin {
  position: absolute;
  top: 150px;
  left: 50px;
}

.Config-puyoSkinA {
  position: absolute;
  top: 150px;
  left: 170px;
}

.Config-puyoSkinB {
  position: absolute;
  top: 190px;
  left: 170px;
}

.Config-puyo {
  position: absolute;
  width: 32px;
  height: 32px;
}

.Config-puyoRedA {
  background-image: url(../assets/puyo_r.png);
  top: 146px;
  left: 268px;
}

.Config-puyoGreenA {
  background-image: url(../assets/puyo_g.png);
  top: 146px;
  left: 300px;
}

.Config-puyoBlueA {
  background-image: url(../assets/puyo_b.png);
  top: 146px;
  left: 332px;
}

.Config-puyoYellowA {
  background-image: url(../assets/puyo_y.png);
  top: 146px;
  left: 364px;
}

.Config-puyoRedB {
  background-image: url(../assets/puyo_rB.png);
  top: 186px;
  left: 268px;
}

.Config-puyoGreenB {
  background-image: url(../assets/puyo_gB.png);
  top: 186px;
  left: 300px;
}

.Config-puyoBlueB {
  background-image: url(../assets/puyo_bB.png);
  top: 186px;
  left: 332px;
}

.Config-puyoYellowB {
  background-image: url(../assets/puyo_yB.png);
  top: 186px;
  left: 364px;
}
