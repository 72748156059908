.Replay {
  position: relative;
  margin: 32px auto 0px auto;
  width: 960px;
  height: 540px;
  background-color: #ffffff;
}

.Replay-button {
  font-weight: bold;
  /* padding: 10px 30px; */
  background-color: #24d;
  color: #fff;
  border-style: none;
  box-shadow: 2px 2px 3px 1px #666;
  -moz-box-shadow: 2px 2px 3px 1px #666;
  -webkit-box-shadow: 2px 2px 3px 1px #666;
  text-align: center;
  line-height: 48px;
}

.Replay-button:hover {
  cursor: pointer;
}

.Replay-button-disabled {
  background-color: rgb(12, 36, 145);
  color: #888888;
}

.Replay-buttonStartStop {
  position: absolute;
  top: 140px;
  left: 442px;
  width: 76px;
}

.Replay-chain {
  position: absolute;
  font-weight: bold;
  font-size: 18px;
  top: 276px;
  width: 80px;
  height: 30px;
  text-align: center;
}

.Replay-chain1P {
  left: 340px;
}

.Replay-chain2P {
  right: 340px;
}

.Replay-chartLink {
  position: absolute;
  top: 380px;
}

.Replay-chartLink1P {
  left: 340px;
}

.Replay-chartLink2P {
  right: 340px;
}

.Replay-field {
  position: absolute;
  width: 192px;
  height: 416px;
  background-image: url(../assets/puyo_field_back.png);
  border: 4px solid white;
  top: 64px;
}

.Replay-field1P {
  left: 132px;
}

.Replay-field2P {
  right: 132px;
}

.Replay-isHoldAllClear {
  position: absolute;
  top: 64px;
  width: 192px;
  height: 96px;
  background-image: url(../assets/allclear.png);
}

.Replay-ojamaArea {
  position: absolute;
  width: 192px;
  height: 32px;
  top: 32px;
}

.Replay-ojamaArea1P {
  left: 136px;
}

.Replay-ojamaArea2P {
  right: 136px;
}

.Replay-ojama {
  position: absolute;
  width: 32px;
  height: 32px;
}

.Replay-ojama1 {
  background-image: url(../assets/ojama11.png);
}

.Replay-ojama2 {
  background-image: url(../assets/ojama2.png);
}

.Replay-ojama3 {
  background-image: url(../assets/ojama3.png);
}

.Replay-ojama4 {
  background-image: url(../assets/ojama4.png);
}

.Replay-ojama5 {
  background-image: url(../assets/ojama5.png);
}

.Replay-ojama6 {
  background-image: url(../assets/ojama6.png);
}

.Replay-ojama7 {
  background-image: url(../assets/ojama7.png);
}

.Replay-peke {
  position: absolute;
  top: 32px;
  left: 64px;
  width: 32px;
  height: 32px;
  background-image: url(../assets/peke.png);
}

.Replay-puyo {
  position: absolute;
  width: 32px;
  height: 32px;
}

.Replay-puyoRed {
  background-image: url(../assets/puyo_r.png);
}

.Replay-puyoGreen {
  background-image: url(../assets/puyo_g.png);
}

.Replay-puyoBlue {
  background-image: url(../assets/puyo_b.png);
}

.Replay-puyoYellow {
  background-image: url(../assets/puyo_y.png);
}

.Replay-puyoRedB {
  background-image: url(../assets/puyo_rB.png);
}

.Replay-puyoGreenB {
  background-image: url(../assets/puyo_gB.png);
}

.Replay-puyoBlueB {
  background-image: url(../assets/puyo_bB.png);
}

.Replay-puyoYellowB {
  background-image: url(../assets/puyo_yB.png);
}

.Replay-puyoOjama {
  background-image: url(../assets/puyo_o.png);
}

.Replay-puyoBlank {
  background-image: none;
}

.Replay-frameArea {
  position: absolute;
  width: 100px;
  top: 50px;
  left: 430px;
  text-align: center;
}

.Replay-frameText {
  position: absolute;
  width: 100px;
  font-size: 18px;
}

.Replay-frameNumber {
  position: absolute;
  width: 100px;
  top: 22px;
  font-size: 30px;
  font-weight: bold;
}
.Replay-frameRange {
  position: absolute;
  top: 16px;
  left: 346px;
  width: 260px;
}

.Replay-frameRange input {
  width: 100%;
}
.Replay-next {
  position: absolute;
  width: 32px;
  height: 64px;
  background-color: #454545;
}

.Replay-next11P {
  left: 350px;
  top: 96px;
}

.Replay-next21P {
  left: 370px;
  top: 160px;
}

.Replay-next12P {
  right: 350px;
  top: 96px;
}

.Replay-next22P {
  right: 370px;
  top: 160px;
}

.Replay-nextAxis {
  top: 32px;
}

.Replay-score {
  position: absolute;
  font-weight: bold;
  font-size: 18px;
  top: 490px;
  width: 152px;
  height: 64px;
  text-align: right;
}

.Replay-score1P {
  left: 156px;
}

.Replay-score2P {
  right: 156px;
}

.Replay-scoreLeft {
  position: absolute;
  left: 0px;
}

.Replay-scoreRight {
  position: absolute;
  right: 0px;
}
