.Room-headline {
  text-align: center;
}

.Room-bottomArea {
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 960px;
  height: 200px;
  background-color: #454545;
}

.Room-infoArea {
  width: 450px;
  overflow: hidden;
  white-space: nowrap;
  background-color: #454545;
  color: #ffffff;
  padding: 4px 0 0 10px;
}

.Room-chatArea {
  width: 500px;
  background-color: #454545;
}

.Room-chatInputArea {
  display: flex;
  height: 30px;
  width: 100%;
  background-color: #454545;
}

.Room-chatTitle {
  overflow-x: hidden;
  margin: 3px 0 0 0;
  white-space: nowrap;
  text-align: center;
  width: 120px;
  color: #ffffff;
}

.Room-chatInput {
  /* height: 20px; */
  width: 330px;
  margin: 0 0 0 3px;
}

.Room-chatContentsArea {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 168px;
  width: 498px;
}

.Room-chatContent {
  display: flex;
  height: 32px;
  width: 100%;
  background-color: #454545;
}

.Room-chatContentName {
  white-space: nowrap;
  overflow: hidden;
  height: 32px;
  width: 120px;
  background-color: #ffffff;
}

.Room-chatContentText {
  white-space: nowrap;
  overflow: hidden;
  height: 32px;
  width: 356px;
  margin-left: 4px;
  background-color: #ffffff;
}

.Room-chatContentText a {
  margin: 0 0 0 8px;
}

.Room-chatContentTime {
  margin: 0 4px 0 0;
  color: #888888;
  font-size: 12px;
}

.Rooms ul {
  margin: 0 auto;
  width: 600px;
  text-align: left;
}
