.Game {
  position: relative;
  margin: 32px auto 0px auto;
  width: 960px;
  height: 540px;
  background-color: #ffffff;
}

.Game-chain {
  position: absolute;
  font-weight: bold;
  font-size: 18px;
  top: 276px;
  width: 80px;
  height: 30px;
  text-align: center;
}

.Game-chain1P {
  left: 340px;
}

.Game-chain2P {
  right: 340px;
}

.Game-field {
  position: absolute;
  width: 192px;
  height: 416px;
  background-image: url(../assets/puyo_field_back.png);
  border: 4px solid white;
  top: 64px;
}

.Game-fieldActive {
  border: 4px solid blue;
}

.Game-field1P {
  left: 132px;
}

.Game-field2P {
  right: 132px;
}

.Game-frameArea {
  position: absolute;
  width: 100px;
  top: 50px;
  left: 430px;
  text-align: center;
}

.Game-frameText {
  position: absolute;
  width: 100px;
  font-size: 18px;
}

.Game-frameNumber {
  position: absolute;
  width: 100px;
  top: 22px;
  font-size: 30px;
  font-weight: bold;
}

.Game-ojamaArea {
  position: absolute;
  width: 192px;
  height: 32px;
  top: 32px;
}

.Game-ojamaArea1P {
  left: 136px;
}

.Game-ojamaArea2P {
  right: 136px;
}

.Game-ojama {
  position: absolute;
  width: 32px;
  height: 32px;
}

.Game-ojama1 {
  background-image: url(../assets/ojama11.png);
}

.Game-ojama2 {
  background-image: url(../assets/ojama2.png);
}

.Game-ojama3 {
  background-image: url(../assets/ojama3.png);
}

.Game-ojama4 {
  background-image: url(../assets/ojama4.png);
}

.Game-ojama5 {
  background-image: url(../assets/ojama5.png);
}

.Game-ojama6 {
  background-image: url(../assets/ojama6.png);
}

.Game-ojama7 {
  background-image: url(../assets/ojama7.png);
}

.Game-playerInfo {
  position: absolute;
  width: 120px;
  height: 56px;
  top: 8px;
  background-color: #888888;
}

.Game-playerInfo1P {
  left: 8px;
}

.Game-playerInfo2P {
  right: 8px;
}

.Game-playerInfoImage {
  position: absolute;
  background-color: #444444;
  /* background-image: url(../assets/puyo_field_back.png); */
  top: 4px;
  left: 4px;
  width: 112px;
  height: 112px;
}

.Game-playerInfoIsSelect {
  position: absolute;
  background-color: #ff3535;
  width: 112px;
  text-align: center;
  top: 4px;
  left: 4px;
  font-weight: bold;
  color: white;
}

.Game-playerInfoNotSelect {
  background-color: #2bb834;
}

.Game-playerInfoName {
  position: absolute;
  top: 32px;
  left: 4px;
  width: 112px;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  background-color: #cccccc;
  white-space: nowrap;
  overflow: hidden;
}

.Game-puyo {
  position: absolute;
  width: 32px;
  height: 32px;
}

.Game-puyoGhost {
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.4;
}

.Game-puyoRed {
  background-image: url(../assets/puyo_r.png);
}

.Game-puyoGreen {
  background-image: url(../assets/puyo_g.png);
}

.Game-puyoBlue {
  background-image: url(../assets/puyo_b.png);
}

.Game-puyoYellow {
  background-image: url(../assets/puyo_y.png);
}

.Game-puyoRedB {
  background-image: url(../assets/puyo_rB.png);
}

.Game-puyoGreenB {
  background-image: url(../assets/puyo_gB.png);
}

.Game-puyoBlueB {
  background-image: url(../assets/puyo_bB.png);
}

.Game-puyoYellowB {
  background-image: url(../assets/puyo_yB.png);
}

.Game-puyoOjama {
  background-image: url(../assets/puyo_o.png);
}

.Game-puyoBlank {
  background-image: none;
}

.Game-roomName {
  text-align: center;
}

.Game-score {
  position: absolute;
  font-weight: bold;
  font-size: 18px;
  top: 490px;
  width: 152px;
  height: 64px;
  text-align: right;
}

.Game-score1P {
  left: 156px;
}

.Game-score2P {
  right: 156px;
}

.Game-scoreLeft {
  position: absolute;
  left: 0px;
}

.Game-scoreRight {
  position: absolute;
  right: 0px;
}

.Game-button {
  position: absolute;
  font-weight: bold;
  /* padding: 10px 30px; */
  background-color: #24d;
  color: #fff;
  border-style: none;
  box-shadow: 2px 2px 3px 1px #666;
  -moz-box-shadow: 2px 2px 3px 1px #666;
  -webkit-box-shadow: 2px 2px 3px 1px #666;
  font-size: 16px;
  width: 140px;
  height: 48px;
  text-align: center;
  line-height: 48px;
}

.Game-button:hover {
  /* background-color: #24d;
  color: #fff; */
  cursor: pointer;
}

.Game-buttonDisabled {
  background-color: rgb(12, 36, 145);
  color: #888888;
}

.Game-buttonActive {
  background-color: #ffffff;
  color: #888888;
}

.Game-buttonAbort {
  top: 220px;
  left: 430px;
  width: 100px;
  height: 36px;
  background-color: #940000;
  font-size: 14px;
}

.Game-buttonAbort:hover {
  background-color: #b10000;
  color: #fff;
}

.Game-buttonJoin1P {
  top: 210px;
  left: 160px;
}

.Game-buttonLeave1P {
  top: 290px;
  left: 160px;
}

.Game-buttonStart1P {
  top: 370px;
  left: 160px;
}

.Game-buttonJoin2P {
  top: 210px;
  right: 160px;
}

.Game-buttonLeave2P {
  top: 290px;
  right: 160px;
}

.Game-buttonStart2P {
  top: 370px;
  right: 160px;
}

.Game-buttonTurnLeft {
  top: 330px;
  left: 410px;
  width: 64px;
}

.Game-buttonTurnRight {
  top: 330px;
  right: 410px;
  width: 64px;
}

.Game-buttonMoveLeft {
  top: 385px;
  left: 410px;
  width: 64px;
}

.Game-buttonMoveRight {
  top: 385px;
  right: 410px;
  width: 64px;
}

.Game-buttonPutTumo {
  top: 440px;
  left: 410px;
}

.Game-isHoldAllClear {
  position: absolute;
  top: 64px;
  width: 192px;
  height: 96px;
  background-image: url(../assets/allclear.png);
}

.Game-next {
  position: absolute;
  width: 32px;
  height: 64px;
  background-color: #454545;
}

.Game-next11P {
  left: 350px;
  top: 96px;
}

.Game-next21P {
  left: 370px;
  top: 160px;
}

.Game-next12P {
  right: 350px;
  top: 96px;
}

.Game-next22P {
  right: 370px;
  top: 160px;
}

.Game-nextAxis {
  top: 32px;
}

.Game-peke {
  position: absolute;
  top: 32px;
  left: 64px;
  width: 32px;
  height: 32px;
  background-image: url(../assets/peke.png);
}

.Game-remainingFrame {
  position: absolute;
  top: 300px;
  font-size: 18px;
}

.Game-remainingFrame1P {
  left: 350px;
}

.Game-remainingFrame2P {
  left: 550px;
}

.Game-fieldCeiling {
  position: absolute;
  background-color: #454545;
  width: 192px;
  height: 32px;
  top: 68px;
}

.Game-fieldCeiling1P {
  left: 136px;
}

.Game-fieldCeiling2P {
  right: 136px;
}

/* .Game-pos-next11 {
  position: absolute;
  left: 350px;
  top: 96px;
}

.Game-pos-next12 {
  position: absolute;
  left: 370px;
  top: 160px;
}

.Game-pos-next21 {
  position: absolute;
  right: 350px;
  top: 96px;
}

.Game-pos-next22 {
  position: absolute;
  right: 370px;
  top: 160px;
} */

/* ここまで */
/*
.Game-field-active {
  border: 4px solid blue;
}

.Game-pos-field1 {
  left: 132px;
  top: 64px;
}

.Game-pos-field2 {
  right: 132px;
  top: 64px;
}

.Game-size-onoff {
  font-size: 16px;
  width: 110px;
  height: 50px;
}

.Game-pos-onoff1 {
  left: 10px;
  top: 10px;
}

.Game-pos-onoff2 {
  right: 10px;
  top: 10px;
}

.Game-pos-go {
  left: 450px;
  top: 130px;
}

.Game-size-go {
  font-size: 16px;
  width: 42px;
  height: 32px;
}

.Game-pos-reset {
  left: 430px;
  top: 60px;
}

.Game-size-reset {
  font-size: 16px;
  width: 80px;
  height: 32px;
}

.Game-input-seed {
  position: absolute;
  width: 80px;
  height: 20px;
  border: 1px solid gray;
  left: 430px;
  top: 100px;
}

.Game-history {
  position: absolute;
}

.Game-history-tile {
  position: absolute;
  height: 30px;
  width: 120px;
  background-color: white;
  border: 1px solid black;
}

.Game-pos-history1 {
  position: absolute;
  left: 12px;
  top: 64px;
}

.Game-pos-history2 {
  position: absolute;
  right: 132px;
  top: 64px;
}

.Game-next {
  width: 32px;
  height: 64px;
  background-color: black;
}

.Game-pos-next11 {
  position: absolute;
  left: 350px;
  top: 96px;
}

.Game-pos-next12 {
  position: absolute;
  left: 370px;
  top: 160px;
}

.Game-pos-next21 {
  position: absolute;
  right: 350px;
  top: 96px;
}

.Game-pos-next22 {
  position: absolute;
  right: 370px;
  top: 160px;
}

.Game-pos-frame {
  position: absolute;
  left: 430px;
  top: 32px;
}

.Game-pos-chain1 {
  position: absolute;
  left: 350px;
  top: 240px;
}

.Game-pos-chain2 {
  position: absolute;
  right: 350px;
  top: 240px;
}

.Game-pos-score1 {
  position: absolute;
  left: 350px;
  top: 270px;
}

.Game-pos-score2 {
  position: absolute;
  right: 350px;
  top: 270px;
}

.Game-pos-usedScore1 {
  position: absolute;
  left: 350px;
  top: 300px;
}

.Game-pos-usedScore2 {
  position: absolute;
  right: 350px;
  top: 300px;
}

.Game-pos-activeOjama1 {
  position: absolute;
  left: 132px;
  top: 16px;
}

.Game-pos-activeOjama2 {
  position: absolute;
  right: 132px;
  top: 16px;
}

.Game-pos-nonActiveOjama1 {
  position: absolute;
  left: 132px;
  top: 36px;
}

.Game-pos-nonActiveOjama2 {
  position: absolute;
  right: 132px;
  top: 36px;
}

.Game-pos-gameStatusText {
  position: absolute;
  left: 320px;
  bottom: 0px;
}

.Game-puyo {
  position: absolute;
  width: 32px;
  height: 32px;
}

.Game-puyo-kind-red {
  background-image: url(../assets/puyo_r.png);
}

.Game-puyo-kind-green {
  background-image: url(../assets/puyo_g.png);
}

.Game-puyo-kind-blue {
  background-image: url(../assets/puyo_b.png);
}

.Game-puyo-kind-yellow {
  background-image: url(../assets/puyo_y.png);
}

.Game-puyo-kind-ojama {
  background-image: url(../assets/puyo_o.png);
}

.Game-puyo-kind-blank {
  background-image: none;
}

.hidden {
  visibility: hidden;
} */
