.AppFooter {
  margin: 0;
  padding: 0;
}

.AppFooter-twitterButton {
  margin: 10px auto;
  width: 56px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  background-color: #1da1f2;
  color: #ffffff;
  font-family: HelveticaNeue;
  cursor: pointer;
  border-radius: 6px 6px 6px 6px;
}

.AppFooter-twitterButton:hover {
  background-color: #015fd1;
  color: #e1e8ed;
  cursor: pointer;
}
